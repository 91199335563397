




















import DatePicker from "@/components/DatePicker.vue";
import Table from "@/components/Table.vue";
import {
  ApplicationLoginsReportItem,
  useGetApplicationUsersQuery,
} from "@/graphql/types";
import IMenu from "@/interfaces/IMenu";
import { TableField } from "@/types/TableField";
import injectStrict from "@/utilities/injectstrict";
import { useResult } from "@vue/apollo-composable";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { DateTime } from "luxon";
import { ApplicationKey } from "../symbols";

export default defineComponent({
  components: { Table, DatePicker },
  setup(_, { root }) {
    const fromDate = ref(DateTime.now().minus({ month: 1 }));

    const toDate = ref(DateTime.now());

    const application = injectStrict(ApplicationKey);

    const { result, loading } = useGetApplicationUsersQuery(
      () => ({
        id: application.id,
        fromDate: fromDate,
        toDate: toDate,
      }),
      { fetchPolicy: "no-cache" }
    );

    watch(
      () => toDate.value,
      (newValue) => {
        if (newValue <= fromDate.value)
          fromDate.value = newValue.minus({ month: 1 });
      }
    );

    return {
      logins: useResult(
        result,
        [],
        (data) => data.applicationLoginsReport.userLogins
      ),
      fromDate,
      toDate,
      minDate: computed(() => toDate.value.minus({ day: 1 })),
      maxDate: DateTime.now(),
      loading,
      fields: computed<TableField[]>(() => [
        {
          key: "userFullname",
          sortable: true,
          filter: true,
          label: root.$tc("usage_history.fields.user_fullname"),
        },
        {
          key: "organizationName",
          sortable: true,
          filter: true,
          label: root.$tc("usage_history.fields.user_organization"),
        },
        {
          key: "noOfLogins",
          sortable: true,
          label: root.$tc("usage_history.fields.login_count"),
          width: "20%",
        },
      ]),
      options: computed<IMenu<ApplicationLoginsReportItem>[]>(() => []),
    };
  },
});
