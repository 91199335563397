import getStartingLocale from "@/locales/utils/getStartingLocale";
import { DateTime } from "luxon";

export function useFromNowDateFormatted(value: string) {
  const locale: string = getStartingLocale() || "en";
  return DateTime.fromISO(value, { zone: "utc" })
    .setLocale(locale)
    .setZone(DateTime.local().zoneName)
    .toRelative();
}

export function useHasPassed(value: string) {
  return DateTime.fromISO(value, { zone: "utc" }) < DateTime.now();
}
