import IImageConstraint from "@/interfaces/IImageConstraint";
import { getImageSizeFn } from "@/utilities/image";
import isNullEmptyOrUndefined from "@/utilities/isNullEmptyOrUndefined";

export default async function (
  file: File | null,
  constraint: IImageConstraint
) {
  if (!file) {
    return true;
  }
  const dims = await getImageSizeFn(file);
  if (!dims?.width || !dims?.height) {
    return false;
  }

  // validate min max width if specified
  if (
    (!isNullEmptyOrUndefined(constraint.imageMinWidth) &&
      dims.width < constraint.imageMinWidth) ||
    (!isNullEmptyOrUndefined(constraint.imageMaxWidth) &&
      dims.width > constraint.imageMaxWidth)
  ) {
    return false;
  }

  // validate min max height if specified
  if (
    (!isNullEmptyOrUndefined(constraint.imageMinHeight) &&
      dims.height < constraint.imageMinHeight) ||
    (!isNullEmptyOrUndefined(constraint.imageMaxHeight) &&
      dims.height > constraint.imageMaxHeight)
  ) {
    return false;
  }
  return true;
}
