import { ApolloQueryResult, OperationVariables } from "@apollo/client";
import { useQuery } from "@vue/apollo-composable";
import {
  DocumentParameter,
  VariablesParameter,
} from "@vue/apollo-composable/dist/useQuery";
import { ref } from "@vue/composition-api";
import * as VueApolloComposable from "@vue/apollo-composable";

// This functions takes a lazy apollo query, and turns it into a query that is controlled by the user directly. It is called by calling the function fetch, and it will never run without being told to.
export function usePromisable<
  TResult = any,
  TVariables extends OperationVariables = OperationVariables
>(query: VueApolloComposable.UseQueryReturn<TResult, TVariables>) {
  return useLazyApolloQuery<TResult, TVariables>(
    query.document,
    () => query.variables.value
  );
}

export function useLazyApolloQuery<
  TResult = any,
  TVariables extends OperationVariables = OperationVariables
>(
  document: DocumentParameter<TResult, TVariables>,
  variables: () => VariablesParameter<TVariables> | undefined
) {
  const enabled = ref(false);
  const query = useQuery(document, variables, () => ({
    enabled: enabled.value,
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  }));

  const fetch = () =>
    new Promise<ApolloQueryResult<TResult>>((resolve, reject) => {
      enabled.value = true;
      query.onResult((res) => {
        if (!res.loading) {
          enabled.value = false;

          if (!res.data) {
            reject("");
          }
          resolve(res);
        }
      });
      query.onError((err) => {
        enabled.value = false;
        reject(err);
      });
    });

  return {
    fetch,
    ...query,
  };
}
