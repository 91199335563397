














































































































































import {
  HistoryDetail,
  PageParamsGraphType,
  useGetHistoriesQuery,
} from "@/graphql/types";
import { IdKey } from "@/symbols";
import { TableField } from "@/types/TableField";
import injectStrict from "@/utilities/injectstrict";
import { useResult } from "@vue/apollo-composable";
import {
  computed,
  defineComponent,
  reactive,
  watch,
} from "@vue/composition-api";
import Table from "./Table.vue";
import useEscapeHtml from "@/utilities/useEscapeHtml";

export default defineComponent({
  components: {
    Table,
  },
  setup(props, { root }) {
    const id = injectStrict(IdKey);

    const pageParams = reactive<PageParamsGraphType>({
      pageSize: 50,
      currentPage: 1,
    });

    watch(
      () => id.value,
      () => {
        // Reset currentPage if the entity's Id is changed
        pageParams.currentPage = 1;
      },
      { immediate: true }
    );

    const { result, loading, error } = useGetHistoriesQuery(
      () => ({ id: id.value, pageParams: pageParams }),
      { fetchPolicy: "no-cache" }
    );

    return {
      useEscapeHtml,
      loading,
      error,
      pageParams,
      totalCount: useResult(result, 0, (data) => data.histories.totalCount),
      items: useResult(result, [], (data) =>
        data.histories.nodes.map((element) => {
          return {
            ...element,
            _showDetails: false,
          };
        })
      ),
      generateUrl: (action: string, detail: HistoryDetail) => {
        switch (action) {
          case "UserRoleAssignment":
          case "UserRoleDeletion":
          case "OrganizationRoleAssignment":
          case "OrganizationRoleDeletion":
            return "/roles/" + detail.toRefId;
          case "UserApplicationAssignment":
          case "UserApplicationDeletion":
            return "/applications/" + detail.toRefId;
          case "RoleOrganizationAssignment":
          case "RoleOrganizationDeletion":
            return "/organizations/" + detail.toRefId;
          default:
            return "";
        }
      },
      fields: computed<TableField[]>(() => [
        {
          key: "datetime",
          label: root.$tc("history.fields.time"),
        },
        {
          key: "userName",
          label: root.$tc("history.fields.user"),
        },
        {
          key: "action",
          label: root.$tc("history.fields.action"),
        },
      ]),
    };
  },
});
