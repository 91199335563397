
































import Table from "@/components/Table.vue";
import useNavigateToUser from "@/composables/navigation/useNavigateToUser";
import useRemoveOption from "@/composables/options/useRemoveOption";
import useShowOption from "@/composables/options/useShowOption";
import useCanNavigate from "@/composables/useCanNavigate";
import useCanRemove from "@/composables/useCanRemove";
import { useFromNowDateFormatted } from "@/composables/useDateFormat";
import {
  useGetapplicationOwnersQuery,
  User,
  useRemoveApplicationOwnerMutation,
} from "@/graphql/types";
import IMenu from "@/interfaces/IMenu";
import { FullScreenKey } from "@/symbols";
import { TableField } from "@/types/TableField";
import injectStrict from "@/utilities/injectstrict";
import { useResult } from "@vue/apollo-composable";
import { computed, defineComponent, toRef, watch } from "@vue/composition-api";
import { ApplicationKey } from "../symbols";
import AddApplicationOwnerPopup from "./AddApplicationOwnerPopup.vue";

export default defineComponent({
  components: { Table, AddApplicationOwnerPopup },
  setup(_, { root }) {
    const application = injectStrict(ApplicationKey);
    const applicationOwners = toRef(application, "owners");
    const fullscreen = injectStrict(FullScreenKey);
    const { result, loading } = useGetapplicationOwnersQuery(
      () => ({
        input: application.id,
      }),
      { fetchPolicy: "no-cache" }
    );

    const removeMutation = useRemoveApplicationOwnerMutation({});

    watch(
      () => useResult(result).value,
      (newValue) => {
        applicationOwners.value = newValue?.owners;
      }
    );

    async function removeOwner(user: User) {
      var result = await removeMutation.mutate({
        input: {
          userId: user?.id,
          applicationId: application.id,
        },
      });
      if (result?.errors == null) removed(user);
    }

    function added(user: User) {
      applicationOwners.value?.push(user);
    }

    function removed(user: User) {
      applicationOwners.value = applicationOwners.value?.filter(
        (element) => element?.id != user.id
      );
    }

    return {
      application,
      applicationOwners,
      loading,
      fields: computed<TableField[]>(() => [
        {
          label: root.$tc("users.fields.name"),
          sortable: true,
          key: "fullname",
        },
        {
          label: root.$tc("users.fields.email"),
          sortable: true,
          key: "email",
        },
        ...(fullscreen.value
          ? [
              {
                key: "organization.name",
                label: root.$tc("users.fields.organisation"),
                sortable: true,
              },
              {
                key: "lastLogin",
                label: root.$tc("users.fields.lastSeen"),
                sortable: true,
                filter: false,
                width: "10%",
              },
            ]
          : []),
      ]),
      options: computed<IMenu<User>[]>(() => [
        {
          ...useRemoveOption(root.$tc("user.title")),
          command: (item) => {
            if (item) removeOwner(item);
          },
          disabled: () => !useCanRemove("ApplicationOwner", application),
        },
        {
          ...useShowOption(root.$tc("user.title")),
          command: (item) => useNavigateToUser(root.$router, item?.id),
          disabled: (item) => !useCanNavigate("User", item),
        },
      ]),
      added,
      removed,
      useFromNowDateFormatted,
    };
  },
});
