












import { defineComponent } from "@vue/composition-api";
import useEditableComponentProps from "@/composables/useEditableComponentProps";

export default defineComponent({
  props: {
    ...useEditableComponentProps(),
    id: {
      type: String,
      required: true,
    },
  },
});
