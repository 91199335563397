




import VueDropify from "vue-dropify";

export default {
  mixins: [VueDropify],
  methods: {
    setImageSrc() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const parent = this as any;
      const src =
        parent.src === undefined || parent.src === null ? "" : parent.src;
      if (src !== "") {
        parent.images = { src };
      } else {
        parent.images = {};
      }
    },
  },
};
