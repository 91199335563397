import { Application, useUpdateApplicationMutation } from "@/graphql/types";
import { Ref } from "@vue/composition-api";

export default function (
  application: Application,
  coverImageFile: Ref<File | null>,
  logoFile: Ref<File | null>
) {
  const mutation = useUpdateApplicationMutation({});

  function call() {
    mutation.mutate(
      {
        input: {
          id: application.id,
          name: application.name || "",
          description: application.description || "",
          coverImageUrl: application.coverImageUrl ?? "",
          coverImageFile: coverImageFile.value,
          logoUrl: application.logoUrl ?? "",
          logoFile: logoFile.value,
          ownerOrganizationId: application.ownerOrganization?.id,
        },
      },
      {
        context: {
          useMultipart: true,
        },
      }
    );
  }

  return {
    ...mutation,
    call,
  };
}
