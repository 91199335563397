import {
  GetOrganizationsQuery,
  GetOrganizationsQueryVariables,
  Organization,
} from "@/graphql/types";
import { QueryOptions } from "@apollo/client";
import { InjectionKey, Ref } from "@vue/composition-api";

export const OrganizationKey: InjectionKey<Organization> = Symbol();
export const LogoFileKey: InjectionKey<Ref<File | null>> = Symbol();
export const IsNewKey: InjectionKey<Ref<boolean>> = Symbol();
export const ParentQueryKey: InjectionKey<
  QueryOptions<GetOrganizationsQueryVariables, GetOrganizationsQuery>
> = Symbol();
