


























































import AssignButton from "@/components/AssignButton.vue";
import Popover from "@/components/Popover.vue";
import Table from "@/components/Table.vue";
import { WithFilter } from "@/composables/useFilter";
import useCanAssign from "@/composables/useCanAssign";

import {
  OrderDirection,
  PageParamsGraphType,
  useAssignApplicationOwnerMutation,
  useGetUsersQuery,
  User,
  useRemoveApplicationOwnerMutation,
  UserField,
  UserFiltersInput,
  UserFilterType,
  UserSortInfo,
} from "@/graphql/types";
import { TableField } from "@/types/TableField";
import { useResult } from "@vue/apollo-composable";
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  ref,
} from "@vue/composition-api";

export default defineComponent({
  components: { Popover, Table, AssignButton },
  emits: ["added", "removed"],
  props: {
    applicationId: {
      type: String,
      required: true,
    },
    ownerOrganizationId: {
      type: String,
      required: false,
    },
    owners: {
      type: Array as PropType<User[]>,
      default: () => [],
    },
  },
  setup(props, { emit, root }) {
    // Data
    const show = ref(false);

    const filters = reactive<UserFiltersInput>({
      filterType: UserFilterType.All,
      email: "",
      name: "",
      organizationId: props.ownerOrganizationId,
      isContainTextSearch: true,
    });

    const sortInfo = reactive<UserSortInfo>({
      direction: OrderDirection.Asc,
      field: UserField.Name,
    });

    const pageParams = reactive<PageParamsGraphType>({
      pageSize: 50,
      currentPage: 1,
    });

    const { result, loading } = useGetUsersQuery(
      () => ({ filters: filters, sortInfo: sortInfo, pageParams: pageParams }),
      () => ({ enabled: show.value, fetchPolicy: "network-only" })
    );

    const removeMutation = useRemoveApplicationOwnerMutation({});
    const addMutation = useAssignApplicationOwnerMutation({});

    function filtersChanged(newFilters: WithFilter[]) {
      newFilters.forEach((filter) => {
        if (filter.key == "fullname" && filter.filter != filters.name)
          filters.name = filter.filter;
        if (filter.key == "email" && filter.filter != filters.email)
          filters.email = filter.filter;
        if (
          filter.key == "organization.name" &&
          filter.filter != filters.organizationName
        )
          filters.organizationName = filter.filter;

        pageParams.currentPage = 1;
      });
    }

    // Functions
    async function add(user: User) {
      var result = await addMutation.mutate({
        input: {
          applicationId: props.applicationId,
          userId: user.id,
        },
      });
      if (result?.errors == null) emit("added", user);
    }

    async function remove(user: User) {
      var result = await removeMutation.mutate({
        input: {
          applicationId: props.applicationId,
          userId: user.id,
        },
      });
      if (result?.errors == null) emit("removed", user);
    }

    function isAssigned(user: User) {
      return props.owners.some((element) => element.id == user.id);
    }

    return {
      useCanAssign,
      users: useResult(result, [], (data) => data.users.nodes),
      show,
      loading,
      mutationLoading: computed(
        () => addMutation.loading.value || removeMutation.loading.value
      ),
      fields: computed<TableField[]>(() => [
        {
          key: "fullname",
          filter: true,
          focus: true,
          label: root.$tc("users.fields.name"),
        },
      ]),
      filtersChanged,
      isAssigned,
      assignOrRemove: (user: User) =>
        isAssigned(user) ? remove(user) : add(user),
    };
  },
});
