import {
  Application,
  CreateRoleInput,
  useCreateApplicationMutation,
} from "@/graphql/types";
import injectStrict from "@/utilities/injectstrict";
import { Ref } from "@vue/composition-api";
import { ParentQueryKey } from "../symbols";
import useDeleteFromCache from "@/composables/useDeleteFromCache";

export default function (
  application: Application,
  createRoles: Ref<CreateRoleInput[]>,
  coverImageFile: Ref<File | null>,
  logoFile: Ref<File | null>
) {
  const mutation = useCreateApplicationMutation({});
  const parentQuery = injectStrict(ParentQueryKey);

  function call() {
    mutation.mutate(
      {
        input: {
          name: application.name || "",
          description: application.description || "",
          ownerOrganizationId: application.ownerOrganization?.id,
          coverImageFile: coverImageFile.value,
          logoFile: logoFile.value,
          roles: createRoles.value,
        },
      },
      {
        context: {
          useMultipart: true,
        },
        update: (cache, { data: data }) => {
          const cachedData = cache.readQuery(parentQuery);
          cache.writeQuery({
            ...parentQuery,
            data: {
              ...cachedData,
              applications: {
                ...cachedData?.applications,
                totalCount: (cachedData?.applications?.totalCount ?? 0) + 1,
                nodes: [
                  ...(cachedData?.applications?.nodes ?? []),
                  data?.applicationMutation?.createApplication,
                ],
              },
            },
          });

          if (createRoles.value?.length) {
            // Need to update roles as number of roles are created
            useDeleteFromCache(cache, "roles");
          }
        },
      }
    );
  }

  return {
    ...mutation,
    call,
  };
}
