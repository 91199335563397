var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Sidebar',{attrs:{"allowFullscreen":_vm.tabs[_vm.currentTab].supportsFullscreen ? true : false,"isLoading":_vm.loading,"title":_vm.isNew
      ? _vm.$tc('applications.create')
      : _vm.application && _vm.application.name
      ? _vm.application.name
      : ' ',"menuItems":_vm.menuItems}},[(_vm.isNew)?_c('CreateWizard',{staticClass:"mt-3",attrs:{"steps":_vm.steps},model:{value:(_vm.steps[_vm.currentTab]),callback:function ($$v) {_vm.$set(_vm.steps, _vm.currentTab, $$v)},expression:"steps[currentTab]"}}):(_vm.application.ownerOrganization)?_c('ParentView',{attrs:{"title":_vm.application.ownerOrganization.name,"subtitle":_vm.application.ownerOrganization.vat},on:{"click":function($event){return _vm.useNavigateToOrganization(
        _vm.$router,
        _vm.application.ownerOrganization ? _vm.application.ownerOrganization.id : ''
      )}}},[(_vm.currentTab == 0)?_c('div',{staticClass:"d-flex justify-content-end col-3"},[(_vm.application.ownerOrganization)?_c('UpdateOrganizationOwnerPopup',{attrs:{"organization":_vm.application.ownerOrganization},on:{"update:organization":function($event){return _vm.$set(_vm.application, "ownerOrganization", $event)}}}):_vm._e()],1):_vm._e()]):_vm._e(),_c('Tapbar',{attrs:{"hasTabs":!_vm.isNew,"tabs":_vm.tabs},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }