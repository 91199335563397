import { maxLength, required, withI18nMessage } from "@/utilities/validators";
import { helpers } from "@vuelidate/validators";

const { withAsync } = helpers;

export default function (
  uniqueAttributeValidator: () => Promise<boolean>,
  nameValidator: () => Promise<boolean>,
  validAttributeValidator: () => Promise<boolean>
) {
  return {
    attribute: {
      maxLength: maxLength(255),
      required,
      uniqueAttribute: withAsync(withI18nMessage(uniqueAttributeValidator)),
      validAttribute: withAsync(withI18nMessage(validAttributeValidator)),
    },
    name: {
      maxLength: maxLength(255),
      required,
      uniqueName: withAsync(withI18nMessage(nameValidator)),
    },
    description: {
      required,
      maxLength: maxLength(1024),
    },
  };
}
