import useProfile from "./useProfile";
import { Application, Maybe, User, UserRoleAssignment } from "@/graphql/types";
import Constants from "@/constants";

interface RemoveAction {
  UserRoleAssignment: UserRoleAssignment;
  UserApplicationAssignment: { application: Maybe<Application>; user: User };
  ApplicationOwner: Application;
}

/**
 * Returns the calculated result of user being able to remove/delete the wanted entity.
 *
 * All objs must have ID
 *
 * If key is 'UserRoleAssignment' the obj must include user.organization
 *
 * if key is 'UserApplicationAssignment' the obj must include user.organization and application.ownerOrganization
 *
 * @param {K extends keyof Entities} key
 * @param {Maybe<Entities[K]>} obj
 * @returns {boolean}
 */
export default function <K extends keyof RemoveAction>(
  k: K,
  obj: Maybe<RemoveAction[K]>
) {
  const {
    isCentralAdmin,
    isSupportAdmin,
    isLocalAdmin,
    isAppAdmin,
    isHomeOrg,
    homeOrg,
    homeOrgApplications,
    profile,
    effectRole,
  } = useProfile();

  function canRemoveUserRoleAssignment(
    obj: RemoveAction["UserRoleAssignment"]
  ) {
    if (obj.user == null)
      console.warn(
        "RoleAssignment must include user in order to determine rights"
      );
    if (obj.role == null)
      console.warn(
        "RoleAssignment must include role in order to determine rights"
      );
    if (
      obj.user?.id === profile.value?.id &&
      compareBrugerstyringRole(
        obj.role?.attribute ?? "",
        effectRole.value ?? ""
      ) >= 0
    )
      return false;

    if (isCentralAdmin.value) return true;

    // A Local Administrator (LA) or Application Administrator (AA) U1 in organization O1 can remove any role assignment for O1. That is, U1 can remove any role assignment {U1..n, R1..n, O1}
    if (obj.organization == null)
      console.warn(
        "RoleAssignment must include organization in order to determine rights"
      );
    if (
      (isLocalAdmin.value || isAppAdmin.value) &&
      isHomeOrg(obj?.organization?.id)
    )
      return true;
    return false;
  }

  // Compare BS2 role
  // Return
  // 0:  Equal
  // 1:  role1 > role2
  // -1: role1 < role2
  function compareBrugerstyringRole(role1: string, role2: string): number {
    const roleOrders = [
      Constants.BS_CENTRAL_ADMIN,
      Constants.BS_SUPPORT_ADMIN,
      Constants.BS_LOCAL_ADMIN,
      Constants.BS_APP_ADMIN,
      Constants.BS_READER,
    ];

    const role1Index = roleOrders.indexOf(role1);
    const role2Index = roleOrders.indexOf(role2);
    if (role1Index == role2Index) return 0;

    return role2Index > role2Index ? 1 : -1;
  }

  function canRemoveUserApplicationAssingnment(
    obj: RemoveAction["UserApplicationAssignment"]
  ) {
    if (isCentralAdmin.value) return true;

    // If user is local admin, she can only remove application assignment that his organization can manage
    if (
      isLocalAdmin.value &&
      homeOrgApplications.value.some(
        (element) => element?.id == obj?.application?.id
      )
    )
      return true;

    return false;
  }

  function canRemoveApplicationOwner(obj: RemoveAction["ApplicationOwner"]) {
    if (isCentralAdmin.value) return true;

    if (obj?.ownerOrganization == null) {
      console.warn(
        "Application must include ownerOrganization to determine rights"
      );
    }

    if (obj?.ownerOrganization?.id == homeOrg.value?.id) return true;
    return false;
  }

  // Support Admin is reader only => He has no any write permission
  if (isSupportAdmin.value) return false;
  switch (k) {
    case "UserRoleAssignment":
      return canRemoveUserRoleAssignment(
        obj as RemoveAction["UserRoleAssignment"]
      );
    case "UserApplicationAssignment":
      return canRemoveUserApplicationAssingnment(
        obj as RemoveAction["UserApplicationAssignment"]
      );
    case "ApplicationOwner":
      return canRemoveApplicationOwner(obj as RemoveAction["ApplicationOwner"]);
  }

  return false;
}
