


























import JQuery from "jquery";
import { DateTime } from "luxon";

import "bootstrap-datepicker";
import "bootstrap-datepicker/js/locales/bootstrap-datepicker.da";
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import { getDateFormatPattern } from "@/utilities/dateFormatLocale";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
    },
    value: {
      type: Object as PropType<DateTime>,
      required: false,
    },
    maxDate: {
      type: Object as PropType<DateTime>,
      required: false,
    },
    minDate: {
      type: Object as PropType<DateTime>,
      required: false,
    },
  },
  setup(props, { root, emit }) {
    const $ = JQuery;
    const element = ref(null as HTMLElement | null);
    var datePicker: JQuery<HTMLElement> | null = null;

    watch(
      () => props.value,
      (newValue) => {
        datePicker?.datepicker("setDate", newValue?.toJSDate());
      }
    );

    watch(
      () => props.maxDate,
      (newValue) => {
        datePicker?.datepicker(
          "setEndDate",
          newValue ? newValue.toJSDate() : null
        );
      },
      { immediate: true }
    );

    watch(
      () => props.minDate,
      (newValue) => {
        datePicker?.datepicker(
          "setStartDate",
          newValue ? newValue.toJSDate() : null
        );
      },
      { immediate: true }
    );

    onMounted(() => {
      if (!element.value) return;
      datePicker = $(element.value);
      datePicker.datepicker({
        language: root.$i18n.locale,
        showOnFocus: true,
        format: getDateFormatPattern(root.$i18n.locale),
        orientation: "auto",
        autoclose: true,
        templates: {
          leftArrow: '<i class="fas fa-chevron-left"></i>',
          rightArrow: '<i class="fas fa-chevron-right"></i>',
        },
      });

      if (props.value) datePicker.datepicker("setDate", props.value.toJSDate());
      if (props.minDate)
        datePicker.datepicker("setStartDate", props.minDate.toJSDate());
      if (props.maxDate)
        datePicker.datepicker("setEndDate", props.maxDate.toJSDate());

      datePicker.on("changeDate", (event) => {
        const date = DateTime.fromJSDate(event.date);
        if (date.toISO() != props.value?.toISO()) emit("input", date);
      });
      datePicker.on("show", () => {
        root.$emit("select2-opened");
      });

      datePicker.on("hide", () => {
        root.$emit("select2-closed");
      });
    });

    onBeforeUnmount(() => {
      datePicker?.datepicker("remove");
      datePicker = null;
      element.value = null;
    });

    return {
      element,
    };
  },
});
