var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Table',{attrs:{"totalItems":_vm.totalCount,"perPage":_vm.pageParams.pageSize,"disableLocalSort":true,"currentPage":_vm.pageParams.currentPage,"isLoading":_vm.loading,"fields":_vm.fields,"items":_vm.items},on:{"update:currentPage":function($event){return _vm.$set(_vm.pageParams, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.pageParams, "currentPage", $event)}},scopedSlots:_vm._u([{key:"cell(action)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$tc(("action." + value)))+" ")]}},{key:"cell(userName)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(item.user)?_c('router-link',{attrs:{"to":{ name: 'User', params: { id: item.user.id } }}},[_vm._v(_vm._s(item.user.fullname)+" ("+_vm._s(item.user.organization.name)+")")]):_c('span',[_vm._v(_vm._s(value))])]}},{key:"cell(datetime)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(item.details)?_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":item._showDetails ? 'chevron-down' : 'chevron-right'},on:{"click":function($event){item._showDetails = !item._showDetails}}}):_vm._e(),_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value))+" ")]}},{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"card p-3"},[_c('ul',{staticClass:"mb-0"},_vm._l((item.details),function(detail,index){return _c('li',{key:index,staticClass:"mt-2"},[_c('span',{staticStyle:{"display":"block"}},[(detail.property != 'ExpirationTime')?_c('p',{domProps:{"innerHTML":_vm._s(
                _vm.$t(("actionDetails." + (item.action)), {
                  property: _vm.useEscapeHtml(detail.property),
                  from: detail.from
                    ? _vm.useEscapeHtml(detail.from)
                    : _vm.$tc('novalue'),
                  to: _vm.useEscapeHtml(detail.to),
                  toRef: _vm.generateUrl(item.action, detail),
                })
              )}}):(detail.property == 'ExpirationTime')?_c('p',[_vm._v(" "+_vm._s(_vm.$t(detail.to ? "expirationTime" : "noexpirationTime", { to: detail.to, }))+" ")]):_vm._e()])])}),0)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }