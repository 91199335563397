import IImageConstraint from "@/interfaces/IImageConstraint";

export default async function (
  file: File | null,
  constraint: IImageConstraint
) {
  if (!file) {
    return true;
  }
  const isFileSizeGoodFn = (file: File) => {
    const maxFileSizeKB = constraint.maxSizeKB ?? 0; // Zero means no constraint
    return maxFileSizeKB === 0 || file.size <= maxFileSizeKB * 1024; // The file.size is in byte unit and 1KB = 1024 bytes
  };

  return isFileSizeGoodFn(file);
}
