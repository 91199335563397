





































import injectStrict from "@/utilities/injectstrict";
import { defineComponent, ref } from "@vue/composition-api";
import { ApplicationKey } from "../symbols";

/*
  Description: This application-connections component is used to show the application id and allow users to copy it.

  Data: 
    application - Injected and used to show the application id
    copied - Indicate if the application id has been copied to clipboard or not. This also reflects the copy button's icon state.

  Methods:
    copyApplicationId - Copy the application id to clipboard
*/

export default defineComponent({
  setup(_, { root }) {
    const application = injectStrict(ApplicationKey);
    const copied = ref(false);

    const copyApplicationConnectionId = () => {
      copied.value = true;
      // copy the app id to clipboard
      navigator.clipboard.writeText(application.connectionId);
    };

    return {
      application,
      copied,
      copyApplicationConnectionId,
    };
  },
});
