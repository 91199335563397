import {
  Application,
  CreateRoleInput,
  GetApplicationsQuery,
  GetApplicationsQueryVariables,
} from "@/graphql/types";
import { QueryOptions } from "@apollo/client";
import { InjectionKey, Ref } from "@vue/composition-api";

export const ApplicationKey: InjectionKey<Application> = Symbol();
export const CreateRolesKey: InjectionKey<Ref<CreateRoleInput[]>> = Symbol();
export const IsNewKey: InjectionKey<Ref<boolean>> = Symbol();
export const CoverImageFileKey: InjectionKey<Ref<File | null>> = Symbol();
export const LogoFileKey: InjectionKey<Ref<File | null>> = Symbol();
export const ParentQueryKey: InjectionKey<
  QueryOptions<GetApplicationsQueryVariables, GetApplicationsQuery>
> = Symbol();
