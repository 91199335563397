









































import Sidebar, { useConfirmDelete } from "@/components/Sidebar.vue";
import Tapbar from "@/components/Tapbar.vue";
import ITabContent from "@/interfaces/ITabContent";
import {
  computed,
  defineComponent,
  provide,
  reactive,
  ref,
  toRef,
  watch,
} from "@vue/composition-api";
import ApplicationData from "./Components/ApplicationData.vue";
import ApplicationRoles from "./Components/ApplicationRoles.vue";
import UpdateOrganizationOwnerPopup from "./Components/UpdateOrganizationOwnerPopup.vue";
import ApplicationConnections from "./Components/ApplicationConnections.vue";
import CreateWizard from "@/components/CreateWizard.vue";
import IStep from "@/interfaces/IStep";
import useVuelidate from "@vuelidate/core";
import {
  Application,
  CreateRoleInput,
  useGetApplicationQuery,
} from "@/graphql/types";
import IMenu from "@/interfaces/IMenu";
import ApplicationCreated from "./Components/ApplicationCreated.vue";
import {
  ApplicationKey,
  CoverImageFileKey,
  LogoFileKey,
  CreateRolesKey,
} from "./symbols";
import { IsNewKey } from "../Organizations/symbols";
import { useResult } from "@vue/apollo-composable";
import useCreateApplicationMutation from "./Composables/useCreateApplicationMutation";
import useDeleteApplicationMutation from "./Composables/useDeleteApplicationMutation";
import useUpdateApplicationMutation from "./Composables/useUpdateApplicationMutation";
import ParentView from "@/components/ParentView.vue";
import useProfile from "@/composables/useProfile";
import { EditableKey, IdKey } from "@/symbols";
import useGobackButton from "../../composables/buttons/useGobackButton";
import useSaveButton from "../../composables/buttons/useSaveButton";
import useNextStepButton from "../../composables/buttons/useNextStepButton";
import useNavigateToOrganization from "@/composables/navigation/useNavigateToOrganization";
import useCancelButton from "@/composables/buttons/useCancelButton";
import ChangelogTableVue from "@/components/ChangelogTable.vue";
import ApplicationOwnersVue from "./Components/ApplicationOwners.vue";
import ApplicationUsersVue from "./Components/ApplicationUsers.vue";
import useGoToParent from "@/composables/useGoToParent";

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  components: {
    Sidebar,
    Tapbar,
    CreateWizard,
    ParentView,
    UpdateOrganizationOwnerPopup,
  },
  setup(props, { root }) {
    const {
      isCentralAdmin,
      isSupportAdmin,
      isLocalAdmin,
      isTechnicalAdmin,
      profile,
    } = useProfile();
    const editable = computed(
      () => (isCentralAdmin.value || isTechnicalAdmin.value) ?? false
    );

    // Refs
    const isNew = ref(props.id == null);
    const currentTab = ref(0);
    const hasChanges = ref<boolean | null>(null);
    if (isNew.value) hasChanges.value = false;
    const v$ = useVuelidate();
    const application = reactive<Application>({
      id: "",
      name: "",
      description: "",
      coverImageUrl: "",
      logoUrl: "",
      ownerOrganization: undefined,
      roles: {
        nodes: [],
      },
      owners: [],
    });
    const visibleUsageHistory = computed(
      () =>
        isCentralAdmin.value ||
        isSupportAdmin.value ||
        (application.owners &&
          application.owners.some((o) => o?.id == profile.value?.id))
    );
    const coverImage = ref(null as File | null);
    const logoFile = ref(null as File | null);

    const createRoles = ref([] as CreateRoleInput[]);

    const createMutation = useCreateApplicationMutation(
      application,
      createRoles,
      coverImage,
      logoFile
    );

    const deleteMutation = useDeleteApplicationMutation(application);
    const updateMutation = useUpdateApplicationMutation(
      application,
      coverImage,
      logoFile
    );

    provide(ApplicationKey, application);
    provide(IdKey, toRef(application, "id"));
    provide(CreateRolesKey, createRoles);
    provide(IsNewKey, isNew);
    provide(CoverImageFileKey, coverImage);
    provide(LogoFileKey, logoFile);
    provide(EditableKey, editable);

    const { result, loading } = useGetApplicationQuery(
      () => ({ id: props.id }),
      {
        enabled: !isNew.value,
        fetchPolicy: "no-cache",
      }
    );

    watch(
      () => useResult(result).value,
      (newValue) => {
        if (newValue) Object.assign(application, newValue.application);
        v$.value.$reset();
      }
    );

    watch(
      () => [
        application.name,
        application.description,
        coverImage.value,
        application.coverImageUrl,
        logoFile.value,
        application.logoUrl,
      ],
      () => {
        if (loading.value) return;
        if (hasChanges.value == null) {
          hasChanges.value = false;
        } else {
          hasChanges.value = true;
        }
      },
      { deep: true }
    );

    createMutation.onDone(({ data }) => {
      application.id = data?.applicationMutation?.createApplication?.id ?? "";
      currentTab.value++;
    });

    deleteMutation.onDone(() => {
      useGoToParent(root.$router);
    });

    updateMutation.onDone(() => {
      useGoToParent(root.$router);
    });

    return {
      isNew,
      currentTab,
      hasChanges,
      application,
      v$,
      editable,
      loading: computed(
        () =>
          loading.value ||
          deleteMutation.loading.value ||
          createMutation.loading.value ||
          updateMutation.loading.value
      ),
      menuItems: computed<IMenu[]>(() =>
        isNew.value
          ? []
          : [
              {
                name: root.$tc("applications.delete"),
                icon: "trash-alt",
                disabled: () => {
                  // Disable when
                  return (
                    !editable.value || // Not Central admin or
                    useResult(result).value?.brugerstyringApplication?.id ==
                      application.id // Is brugerstyring.dk app
                  );
                },
                command: async () => {
                  if (
                    await useConfirmDelete(
                      application.name ?? "",
                      application.name ?? ""
                    )
                  )
                    deleteMutation.call();
                },
              },
            ]
      ),
      useNavigateToOrganization,
      tabs: computed<ITabContent[]>(() =>
        isNew.value
          ? [
              {
                title: root.$tc("common.steps.data"),
                component: ApplicationData,
                primaryButton: {
                  ...useNextStepButton(async () => {
                    if (await v$.value.$validate()) currentTab.value++;
                  }),
                  disabled:
                    !editable.value ||
                    (!hasChanges.value ?? false) ||
                    v$.value.$pending ||
                    v$.value.$errors.length > 0,
                },
                secondaryButton: useCancelButton(() =>
                  useGoToParent(root.$router)
                ),
              },
              {
                title: root.$tc("applications.tabs.roles"),
                component: ApplicationRoles,
                primaryButton: {
                  title: root.$tc("applications.create"),
                  variant: "success",
                  onClick: () => createMutation.call(),
                  disabled: !editable.value,
                },
                secondaryButton: useGobackButton(() => {
                  v$.value.$reset();
                  currentTab.value--;
                }),
              },
              {
                title: root.$tc("common.steps.done"),
                component: ApplicationCreated,
              },
            ]
          : [
              {
                title: root.$tc("common.steps.data"),
                component: ApplicationData,
                primaryButton: {
                  ...useSaveButton(async () => {
                    if (await v$.value.$validate()) updateMutation.call();
                  }),
                  disabled:
                    !editable.value ||
                    (!hasChanges.value ?? false) ||
                    v$.value.$errors.length > 0,
                },
                secondaryButton: useCancelButton(() =>
                  useGoToParent(root.$router)
                ),
              },
              {
                title: root.$tc("applications.tabs.roles"),
                component: ApplicationRoles,
                supportsFullscreen: true,
              },
              {
                title: root.$tc("applications.tabs.owners"),
                component: ApplicationOwnersVue,
                supportsFullscreen: true,
                visible:
                  isCentralAdmin.value ||
                  isSupportAdmin.value ||
                  isLocalAdmin.value,
              },

              {
                title: root.$tc("usage_history.title"),
                component: ApplicationUsersVue,
                visible: visibleUsageHistory.value,
              },
              {
                title: root.$tc("applications.tabs.connections"),
                component: ApplicationConnections,
                visible: isCentralAdmin.value || isSupportAdmin.value,
              },
              {
                title: root.$tc("history.title"),
                component: ChangelogTableVue,
                visible: isCentralAdmin.value || isSupportAdmin.value,
              },
            ]
      ),
      steps: computed<IStep[]>(() => [
        {
          title: root.$tc("common.steps.data"),
          icon: "database",
        },
        {
          title: root.$tc("roles.title"),
          icon: "share-alt",
        },
        {
          title: root.$tc("common.steps.done"),
          icon: "check",
        },
      ]),
    };
  },
});
