import IImageConstraint from "@/interfaces/IImageConstraint";

interface IDimentions {
  width: number;
  height: number;
}
const imageSizeVariation = 5; // in pixel
const maxImageSizeKB = 200; // in KB
const URLClass = window.URL || window.webkitURL;
const getImageSizeFn = (
  image: File
): Promise<{ width: number; height: number } | null> => {
  const img = new Image();
  return new Promise((resolve, _) => {
    // there're many ways of checking the dimentions of the uploaded file but `createObjectURL` give
    // the fastest result even doing with large file upload
    const objectUrl = URLClass.createObjectURL(image);
    img.onload = () => {
      const dimentions: IDimentions = {
        width: img.width,
        height: img.height,
      };
      // revoke object URL is important to release resouces for the device
      URLClass.revokeObjectURL(objectUrl);
      resolve(dimentions);
    };
    // return null on error
    img.onerror = resolve.bind(null, null);
    // set the source to trigger onload event
    img.src = objectUrl;
  });
};

const getLogoConstraint = () => {
  const logoHeight = 54;
  const logoWidth = 54;
  const constraint: IImageConstraint = {
    imageMinWidth: logoWidth - imageSizeVariation,
    imageMaxWidth: logoWidth + imageSizeVariation,
    imageMinHeight: logoHeight - imageSizeVariation,
    imageMaxHeight: logoHeight + imageSizeVariation,
    maxSizeKB: maxImageSizeKB,
  };

  return constraint;
};

const getCoverImageConstraint = () => {
  const imageHeight = 900;
  const imageWidth = 525;
  const constraint: IImageConstraint = {
    imageMinWidth: imageWidth - imageSizeVariation,
    imageMaxWidth: imageWidth + imageSizeVariation,
    imageMinHeight: imageHeight - imageSizeVariation,
    imageMaxHeight: imageHeight + imageSizeVariation,
    maxSizeKB: maxImageSizeKB,
  };

  return constraint;
};
export { getImageSizeFn, getLogoConstraint, getCoverImageConstraint };
