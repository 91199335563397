











import IButton from "@/interfaces/IButton";
import { defineComponent, PropType } from "@vue/composition-api";
import Button from "./Button.vue";

export default defineComponent({
  components: { Button },
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    primaryButton: {
      type: Object as PropType<IButton>,
      required: false,
    },
    secondaryButton: {
      type: Object as PropType<IButton>,
      required: false,
    },
  },
});
