



















































































import Dropzone from "@/components/Dropzone.vue";
import InputGroup from "@/components/InputGroup.vue";
import injectStrict from "@/utilities/injectstrict";
import {
  defineComponent,
  inject,
  ref,
  toRef,
  toRefs,
} from "@vue/composition-api";
import { ApplicationKey, CoverImageFileKey, LogoFileKey } from "../symbols";
import useVuelidate from "@vuelidate/core";
import { useApplicationByNameLazyQuery } from "@/graphql/types";
import { IsNewKey } from "@/views/Organizations/symbols";
import { maxLength, required, withI18nMessage } from "@/utilities/validators";
import { helpers } from "@vuelidate/validators";
import { usePromisable } from "@/utilities/useLazyApolloQuery";
import useImageDimensionValidator from "@/composables/useImageDimensionValidator";
import useImageSizeValidator from "@/composables/useImageSizeValidator";
import { EditableKey } from "@/symbols";
import OrganizationSelector from "@/components/Selectors/OrganizationSelector.vue";
import IImageConstraint from "@/interfaces/IImageConstraint";
import { getLogoConstraint, getCoverImageConstraint } from "@/utilities/image";

const { withAsync } = helpers;

export default defineComponent({
  components: { Dropzone, InputGroup, OrganizationSelector },
  setup() {
    const isNew = injectStrict(IsNewKey);
    const coverImageFile = injectStrict(CoverImageFileKey);
    const logoFile = injectStrict(LogoFileKey);
    const application = injectStrict(ApplicationKey);
    const editable = inject(EditableKey, ref(false));

    const { fetch: fetchUniqueName } = usePromisable(
      useApplicationByNameLazyQuery(() => ({
        name: application.name || "",
      }))
    );

    async function uniqueNameValidator() {
      if (!v$.value.name.$dirty || application.name == "") return true;
      var result = await fetchUniqueName();
      if (
        result &&
        result.data &&
        result.data.applicationByName != null &&
        result.data.applicationByName.id != application.id
      )
        return false;
      return true;
    }
    const logoConstraint: IImageConstraint = getLogoConstraint();
    const coverImageConstraint: IImageConstraint = getCoverImageConstraint();

    async function logoDimensionValidator() {
      return await useImageDimensionValidator(logoFile.value, logoConstraint);
    }
    async function logoSizeValidator() {
      return await useImageSizeValidator(logoFile.value, logoConstraint);
    }
    async function coverImageDimensionValidator() {
      return await useImageDimensionValidator(
        coverImageFile.value,
        coverImageConstraint
      );
    }
    async function coverImageSizeValidator() {
      return await useImageSizeValidator(
        coverImageFile.value,
        coverImageConstraint
      );
    }

    const v$ = useVuelidate(
      useValidations(
        uniqueNameValidator,
        logoDimensionValidator,
        logoSizeValidator,
        coverImageDimensionValidator,
        coverImageSizeValidator
      ),
      {
        ...toRefs(application),
        ownerOrganization: toRef(application, "ownerOrganization"),
        logoFile: logoFile,
        coverImageFile: coverImageFile,
      } as any,
      { $autoDirty: true }
    );
    return {
      isNew,
      editable,
      coverImageFile,
      logoFile,
      application,
      v$,
    };
  },
});

function useValidations(
  uniqueNameValidator: () => Promise<boolean>,
  logoDimemsionValidator: () => Promise<boolean>,
  logoSizeValidator: () => Promise<boolean>,
  coverImageDimemsionValidator: () => Promise<boolean>,
  coverImageSizeValidator: () => Promise<boolean>
) {
  return {
    ownerOrganization: { required },
    name: {
      required,
      maxLength: maxLength(255),
      uniqueName: withAsync(withI18nMessage(uniqueNameValidator)),
    },
    description: { maxLength: maxLength(1024) },
    logoFile: {
      logoDimension: withAsync(withI18nMessage(logoDimemsionValidator)),
      maxFileSize: withAsync(withI18nMessage(logoSizeValidator)),
    },
    coverImageFile: {
      coverImageDimension: withAsync(
        withI18nMessage(coverImageDimemsionValidator)
      ),
      maxFileSize: withAsync(withI18nMessage(coverImageSizeValidator)),
    },
  };
}
