














import {
  Organization,
  useGetSelectableOrganizationQuery,
} from "@/graphql/types";
import useEscapeHtml from "@/utilities/useEscapeHtml";
import { useResult } from "@vue/apollo-composable";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import Select from "../Select.vue";

export default defineComponent({
  components: {
    Select,
  },
  props: {
    disabledItems: {
      type: Array as PropType<Organization[]>,
      default: () => [],
    },
    value: {
      type: Object as PropType<Organization>,
    },
  },
  setup(props, { emit }) {
    const { result, loading } = useGetSelectableOrganizationQuery();

    const templateResult = (option: any) => {
      let html = "<div>";
      html += useEscapeHtml(option?.object?.name);
      html += "</div>";
      html += '<small class="text-muted">' + option?.object?.vat + "</small>";

      return html;
    };

    return {
      loading,
      mOrganization: computed<Organization | undefined>({
        get() {
          return props.value;
        },
        set(newValue: Organization | undefined): void {
          emit("input", newValue);
        },
      }),
      organizations: useResult(result, [], (data) => data.organizations.nodes),
      templateResult,
    };
  },
});
