import useDeleteFromCache from "@/composables/useDeleteFromCache";
import { Role, useCreateRoleMutation } from "@/graphql/types";
import injectStrict from "@/utilities/injectstrict";
import { ParentQueryKey } from "../symbols";

export default function () {
  const mutation = useCreateRoleMutation({});
  const parentQuery = injectStrict(ParentQueryKey);

  async function call(role: Role) {
    return mutation.mutate(
      {
        input: {
          name: role.name ?? "",
          description: role.description ?? "",
          attribute: role.attribute ?? "",
          applicationId: role.application?.id ?? undefined,
          organizationsIds: role.assignees?.map((element) => element?.id),
        },
      },
      {
        awaitRefetchQueries: true,
        refetchQueries: [parentQuery],
        update: (cache) => {
          useDeleteFromCache(cache, "roles");
        },
      }
    );
  }

  return {
    ...mutation,
    call,
  };
}
