














import { computed, defineComponent } from "@vue/composition-api";
import Message from "@/components/Message.vue";
import injectStrict from "@/utilities/injectstrict";
import { ApplicationKey } from "../symbols";
import IButton from "@/interfaces/IButton";

export default defineComponent({
  components: {
    Message,
  },
  setup(_, { root }) {
    const application = injectStrict(ApplicationKey);
    return {
      primaryButton: computed<IButton>(() => {
        return {
          title: root.$tc("applications.show").toString(),
          variant: "success",
          onClick: async () => {
            await root.$router.back();
            setTimeout(() => {
              root.$router.push({
                name: "Application",
                params: { id: application.id?.toString() ?? "" },
              });
            }, 500);
          },
        };
      }),
      secondaryButton: computed<IButton>(() => {
        return {
          title: root.$tc("common.done"),
          variant: "info",
          onClick: () => {
            root.$router.back();
          },
        };
      }),
    };
  },
});
