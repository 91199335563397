



























import Dropdown from "@/components/Dropdown.vue";
import Table from "@/components/Table.vue";
import Tooltip from "@/components/Tooltip.vue";
import useNavigateToRole from "@/composables/navigation/useNavigateToRole";
import useDeleteOption from "@/composables/options/useDeleteOption";
import useShowOption from "@/composables/options/useShowOption";
import useCanNavigate from "@/composables/useCanNavigate";
import {
  CreateRoleInput,
  Role,
  useGetApplicationRolesQuery,
} from "@/graphql/types";
import IMenu from "@/interfaces/IMenu";
import { EditableKey, FullScreenKey } from "@/symbols";
import { TableField } from "@/types/TableField";
import injectStrict from "@/utilities/injectstrict";
import { IsNewKey } from "@/views/Organizations/symbols";
import { useResult } from "@vue/apollo-composable";
import { computed, defineComponent } from "@vue/composition-api";
import useCreateRoleMutation from "../Composables/useCreateRoleMutation";
import useDeleteRoleMutation from "../Composables/useDeleteRoleMutation";
import { ApplicationKey, CreateRolesKey } from "../symbols";
import CreateRolePopup from "./CreateRolePopup.vue";

export default defineComponent({
  components: {
    Table,
    CreateRolePopup,
    Dropdown,
    Tooltip,
  },
  setup(_, { root }) {
    // Refs
    const application = injectStrict(ApplicationKey);
    const isNew = injectStrict(IsNewKey);
    const createRoles = injectStrict(CreateRolesKey);
    const editable = injectStrict(EditableKey);
    const fullscreen = injectStrict(FullScreenKey);

    const { result, loading, error, refetch } = useGetApplicationRolesQuery(
      () => ({ ID: application.id }),
      { enabled: !isNew.value, fetchPolicy: "no-cache" }
    );

    const createApplicationRoleMutation = useCreateRoleMutation();
    const deleteApplicationRoleMutation = useDeleteRoleMutation();

    // Functions
    // if isNew = true, the new role will be added to createRoles
    // else call mutation to add the new role to the application then refetch the application roles
    function addRole(role: CreateRoleInput) {
      return isNew.value
        ? createRoles.value.push(role)
        : createApplicationRoleMutation
            .call({ ...role, application: application })
            .then(() => refetch());
    }

    // if isNew = true, the new role will be removed from createRoles
    // else call mutation to remove the role from the application then refetch the application roles
    async function removeRole(item: Role) {
      if (isNew.value) {
        createRoles.value = createRoles.value.filter(
          (element) => element.name != item.name
        );
      } else {
        await deleteApplicationRoleMutation.call(item);
        refetch();
      }
    }

    return {
      isNew,
      result,
      loading,
      error,
      editable,
      roles: computed(() =>
        isNew.value
          ? createRoles.value
          : useResult(result, [], (data) => data.application.roles.nodes)
              .value ?? []
      ),
      addRole,
      removeRole,
      fields: computed<TableField[]>(() => [
        {
          key: "name",
          label: root.$tc("roles.fields.name"),
          sortable: true,
          filter: true,
        },
        {
          key: "attribute",
          label: root.$tc("roles.fields.attribute"),
          sortable: true,
          filter: true,
        },
        ...(fullscreen.value && !isNew.value
          ? [
              {
                key: "assignedUsers.totalCount",
                label: root.$tc("roles.fields.users_count"),
                sortable: true,
                filter: false,
                width: "15%",
              },
            ]
          : []),
      ]),
      menuItems: computed<IMenu<Role>[]>(() =>
        !isNew.value
          ? [
              {
                ...useShowOption(root.$tc("role.title")),
                disabled: (role) =>
                  !useCanNavigate("Role", {
                    ...role,
                    application: application,
                  }),
                command: (item) => useNavigateToRole(root.$router, item?.id),
              },
              {
                ...useDeleteOption(root.$tc("role.title")),
                disabled: () => !editable.value,
                command: (item) => (item != null ? removeRole(item) : null),
              },
            ]
          : [
              {
                ...useDeleteOption(root.$tc("role.title")),
                requireConfirmation: true,
                command: (item) => (item != null ? removeRole(item) : null),
              },
            ]
      ),
    };
  },
});
