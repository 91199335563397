























import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import VueDropify from "@/components/MyVueDropify.vue";
import useEditableComponentProps from "@/composables/useEditableComponentProps";

export default defineComponent({
  props: {
    ...useEditableComponentProps(),
    value: {
      type: File,
      required: false,
    },
    requestedHeight: {
      type: Number,
      default: 54,
    },
    requestedWidth: {
      type: Number,
      default: 54,
    },
    url: {
      type: String,
      required: false,
    },
  },
  components: {
    VueDropify,
  },
  setup(props, { emit }) {
    const image = ref<FileList | null>(null);
    const src = ref<string | null>();

    if (props.value) {
      const reader = new FileReader();
      reader.readAsDataURL(props.value);
      reader.onload = () => (src.value = reader.result?.toString());
    }

    watch(
      () => props.url,
      (newValue) => {
        if (props.value == null && props.url != null) {
          src.value = newValue === "" ? null : newValue;
        }
      },
      { immediate: true }
    );

    watch(
      () => image.value,
      (newValue) => {
        if (newValue && newValue?.length >= 1) {
          emit("input", newValue.item(0));
        } else {
          src.value = null;
          emit("input", null);
          emit("update:url", null);
        }
      }
    );

    return {
      image,
      src,
      dimensions: computed(() => {
        return { width: props.requestedWidth, height: props.requestedHeight };
      }),
    };
  },
});
