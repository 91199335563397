import VueRouter from "vue-router";

export default function (router: VueRouter, id: string | null) {
  id
    ? router.push({
        name: "User",
        params: { id: id },
      })
    : router.push({
        name: "Assign",
      });
}
