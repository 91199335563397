





























import InputGroup from "@/components/InputGroup.vue";
import Popover from "@/components/Popover.vue";
import Select from "@/components/Select.vue";
import useCancelButton from "@/composables/buttons/useCancelButton";
import useSaveButton from "@/composables/buttons/useSaveButton";
import {
  Organization,
  UpdateApplicationInput,
  useUpdateApplicationMutation,
} from "@/graphql/types";
import IButton from "@/interfaces/IButton";
import { EditableKey } from "@/symbols";
import injectStrict from "@/utilities/injectstrict";
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { ApplicationKey } from "../symbols";
import OrganizationSelector from "@/components/Selectors/OrganizationSelector.vue";

export default defineComponent({
  emits: ["updated"],
  components: {
    Popover,
    InputGroup,
    Select,
    OrganizationSelector,
  },
  props: {
    organization: {
      type: Object as PropType<Organization>,
      required: true,
    },
  },
  setup(props, { emit, root }) {
    // Data
    const editable = injectStrict(EditableKey);
    const targetOrganization = ref(props.organization);
    const show = ref(false);
    const application = injectStrict(ApplicationKey);

    const updateApplicationInput = reactive<UpdateApplicationInput>({
      id: application.id,
      name: application.name ?? "",
      description: application.description ?? "",
      coverImageUrl: application.coverImageUrl ?? "",
      ownerOrganizationId: "",
    });

    watch(
      () => targetOrganization.value,
      (newValue) => {
        updateApplicationInput.ownerOrganizationId = newValue.id;
      }
    );

    const mutation = useUpdateApplicationMutation({});

    async function updateApplicationOrganizationOwner() {
      updateApplicationInput.ownerOrganizationId = targetOrganization.value.id;
      await mutation.mutate({ input: updateApplicationInput });
      emit("update:organization", targetOrganization.value);
      show.value = false;
    }

    return {
      targetOrganization,
      editable,
      show,
      primaryButton: computed<IButton>(() => {
        return {
          ...useSaveButton(() => updateApplicationOrganizationOwner()),
          disabled:
            updateApplicationInput.ownerOrganizationId === "" ||
            updateApplicationInput.ownerOrganizationId ===
              props.organization.id,
        };
      }),
      secondaryButton: useCancelButton(() => (show.value = false)),
    };
  },
});
