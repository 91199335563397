















































import InputGroup from "@/components/InputGroup.vue";
import Popover from "@/components/Popover.vue";
import {
  CreateRoleInput,
  Role,
  useRoleByAttributeLazyQuery,
} from "@/graphql/types";
import IButton from "@/interfaces/IButton";
import useValidations from "@/views/Roles/Composables/useValidations";
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  ref,
  toRefs,
  watch,
} from "@vue/composition-api";
import useVuelidate from "@vuelidate/core";
import { usePromisable } from "@/utilities/useLazyApolloQuery";

export default defineComponent({
  emits: ["created"],
  components: {
    Popover,
    InputGroup,
  },
  props: {
    roles: {
      type: Array as PropType<Role[]>,
      default: () => [],
    },
  },
  setup(props, { emit, root }) {
    // Data
    const show = ref(false);
    const hasChanges = ref<boolean>(false);
    const role = reactive<CreateRoleInput>({
      attribute: "",
      name: "",
      description: "",
    });

    const validAttributeValidator = async () => {
      if (
        !v$.value.attribute.$dirty ||
        role.attribute === null ||
        role.attribute === ""
      )
        return true;

      return !role.attribute?.includes("~") ?? true;
    };

    const v$ = useVuelidate(
      useValidations(
        uniqueAttributeValidator,
        uniqueNameValidator,
        validAttributeValidator
      ),
      {
        ...toRefs(role),
      },
      { $stopPropagation: true, $lazy: true }
    );

    const { fetch: fetchUniqueAttribute } = usePromisable(
      useRoleByAttributeLazyQuery(() => ({
        attribute: role.attribute || "",
      }))
    );
    async function uniqueAttributeValidator() {
      if (role.attribute == "") return true;
      if (props.roles.some((element) => element.attribute == role.attribute))
        return false;
      return true;
    }

    async function uniqueNameValidator() {
      if (
        props.roles.some(
          (element) => element.name?.toLowerCase() == role.name.toLowerCase()
        )
      )
        return false;
      return true;
    }

    async function create() {
      if (!(await v$.value.$validate())) return;
      emit("created", { ...role });
      show.value = false;
    }

    watch(
      () => show.value,
      (newValue) => {
        if (newValue) return;
        hasChanges.value = false;
        v$.value.$reset();
        role.name = "";
        role.attribute = "";
        role.description = "";
      }
    );

    watch(
      () => role,
      () => {
        // Popup is closed, no need to update chagne
        if (!show.value) return;

        if (hasChanges.value === false) hasChanges.value = true;
      },
      { deep: true }
    );

    const roleAttributeKeydown = (e: any) => {
      if (e?.key === "~") {
        e.preventDefault();
      }
    };

    return {
      show,
      role,
      v$,
      create,
      roleAttributeKeydown,
      primaryButton: computed<IButton>(() => {
        return {
          title: root.$tc("common.add"),
          variant: "success",
          onClick: () => create(),
          disabled:
            (!hasChanges.value ?? false) ||
            v$.value.$errors.length > 0 ||
            v$.value.$pending,
        };
      }),
      secondaryButton: computed<IButton>(() => {
        return {
          title: root.$tc("common.cancel"),
          variant: "outline-info",
          onClick: () => (show.value = false),
        };
      }),
    };
  },
});
