/**
 * Get the date format pattern for the given locale.
 * @example
 *     getDateFormatPattern('en-AU');   // dd/mm/yyyy
 *     getDateFormatPattern('en-US');   // m/d/yyyy
 */
export const getDateFormatPattern = (locale: string) => {
  const getPatternForPart = (part: Intl.DateTimeFormatPart) => {
    switch (part.type) {
      case "day":
        return "d".repeat(part.value.length);
      case "month":
        return "m".repeat(part.value.length);
      case "year":
        return "y".repeat(part.value.length);
      case "literal":
        return part.value;
      default:
        console.log("Unsupported date part", part);
        return "";
    }
  };

  return new Intl.DateTimeFormat(locale)
    .formatToParts(new Date("2021-01-01"))
    .map(getPatternForPart)
    .join("");
};
