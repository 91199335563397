import { Application, User, Maybe } from "@/graphql/types";
import useProfile from "./useProfile";
interface AssignAction {
  UserApplicationAssignment: void;
  ApplicationOwner: Application | string;
  UserRoleAssignment: User;
}

export default function <K extends keyof AssignAction>(
  k: K,
  obj: Maybe<AssignAction[K]>
) {
  const {
    isCentralAdmin,
    isSupportAdmin,
    isLocalAdmin,
    isAppAdmin,
    isHomeOrg,
    homeOrg,
  } = useProfile();
  function canAssignUserRoleAssignment(
    obj: AssignAction["UserRoleAssignment"]
  ) {
    if (isCentralAdmin.value) {
      return true;
    }

    // If user is local admin or app admin for application, she can only remove roles from users from own org
    if (obj?.organization == null) {
      console.warn(
        "User must include organization in order to determine rights"
      );
    }
    if (
      (isLocalAdmin.value || isAppAdmin.value) &&
      isHomeOrg(obj?.organization)
    ) {
      return true;
    }
    return false;
  }
  function canAssignApplicationOwner(obj: AssignAction["ApplicationOwner"]) {
    if (isCentralAdmin.value) {
      return true;
    }

    if (typeof obj == "string") {
      return obj == homeOrg.value?.id;
    }

    if (obj == undefined || obj?.ownerOrganization == null) {
      console.warn(
        "Application must include ownerOrganization to determine rights"
      );
    }

    if (obj?.ownerOrganization?.id == homeOrg.value?.id) {
      return true;
    }
    return false;
  }

  if (isCentralAdmin.value) {
    return true;
  }
  // Support Admin is reader only => He has no any write permission
  if (isSupportAdmin.value) {
    return false;
  }
  switch (k) {
    case "UserApplicationAssignment":
      if (isLocalAdmin.value) {
        return true;
      }
      break;
    case "ApplicationOwner":
      return canAssignApplicationOwner(obj as AssignAction["ApplicationOwner"]);
    case "UserRoleAssignment":
      return canAssignUserRoleAssignment(
        obj as AssignAction["UserRoleAssignment"]
      );
  }

  return false;
}
