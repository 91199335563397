import useDeleteFromCache from "@/composables/useDeleteFromCache";
import { Role, useDeleteRoleMutation } from "@/graphql/types";
import injectStrict from "@/utilities/injectstrict";
import { ParentQueryKey } from "../symbols";

export default function () {
  const mutation = useDeleteRoleMutation({});
  const parentQuery = injectStrict(ParentQueryKey);

  async function call(role: Role) {
    return await mutation.mutate(
      {
        id: role.id,
      },
      {
        awaitRefetchQueries: true,
        refetchQueries: [parentQuery],
        update: (cache) => {
          useDeleteFromCache(cache, "roles");
        },
      }
    );
  }

  return {
    ...mutation,
    call,
  };
}
