import useDeleteFromCache from "@/composables/useDeleteFromCache";
import { Application, useDeleteApplicationMutation } from "@/graphql/types";
import injectStrict from "@/utilities/injectstrict";
import { ParentQueryKey } from "../symbols";

export default function (application: Application) {
  const mutation = useDeleteApplicationMutation({});
  const parentQuery = injectStrict(ParentQueryKey);

  function call() {
    mutation.mutate(
      {
        id: application.id,
      },
      {
        update: (cache) => {
          const cachedData = cache.readQuery(parentQuery);
          cache.writeQuery({
            ...parentQuery,
            data: {
              ...cachedData,
              applications: {
                ...cachedData?.applications,
                totalCount: (cachedData?.applications?.totalCount ?? 0) - 1,
                nodes:
                  cachedData?.applications?.nodes?.filter(
                    (element) => element?.id != application.id
                  ) ?? [],
              },
            },
          });

          // This application might have had roles associated to it.
          useDeleteFromCache(cache, "roles");
        },
      }
    );
  }

  return {
    ...mutation,
    call,
  };
}
